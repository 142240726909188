@-webkit-keyframes marquee {
  0% {
    transform: translate3d(calc(-2 * calc(100% / 16)), 0, 0);
  }
  100% {
    transform: translate3d(calc(-1 * calc(100% / 16)), 0, 0);
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(calc(-2 * calc(100% / 16)), 0, 0);
  }
  100% {
    transform: translate3d(calc(-1 * calc(100% / 16)), 0, 0);
  }
}
.sidebar__marquee__inner {
  -webkit-animation: marquee calc(281 * 0.015s) linear infinite;
  animation: marquee calc(281 * 0.015s) linear infinite;
}
